import {TopBar} from "../menu/TopBar";
import LeftSideMenu from "../menu/LeftSideMenu";
import React, {useCallback} from "react";
import {Footer} from "./Footer";

export const PageWrapper = ({children}) => {
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
    const handleDrawerToggle = useCallback(() => {
        setIsDrawerOpen(!isDrawerOpen);
    }, [isDrawerOpen]);

    return <div className="App">
        <TopBar handleDrawerToggle={handleDrawerToggle}/>
        <div className={"below-top-bar"}>

            <LeftSideMenu open={isDrawerOpen} handleDrawerToggle={handleDrawerToggle}/>

            <div className={"main-content-area"}>
                <div className={"main-content-area-inner"}>
                    {children}
                </div>

                <Footer />
            </div>
        </div>
    </div>
};