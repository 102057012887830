import {useParams} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {PageWrapper} from "../app/PageWrapper";
import React from "react";
import Loadable from 'react-loadable';
import {ArticleView} from "../../../articles/frontend/ArticleView";

const LoadableEditPage = Loadable({
    loader: () => import('../../../articles/frontend/ArticleEditor'),
    loading: () => <div />,
});


export const ViewArticlePage = (props) => {
    const params = useParams();
    const articleId = params.articleId;

    const {isAuthenticated, isLoading} = useAuth0();

    if (isLoading) {
        return null;
    }

    if (!isAuthenticated) {
        return <PageWrapper>
            <ArticleView articleId={articleId} key={articleId} />
        </PageWrapper>;
    } else {
        return <PageWrapper>
            <LoadableEditPage articleId={articleId} key={articleId} />
        </PageWrapper>;
    }
}
