import React from "react";
import "./ArticleSectionDisplay.scss";


export function ArticleSectionDisplay({prompt}) {
    // Check if the result is an old-style plain-text section,
    // or a new-style rich-text section
    const isRichText = prompt.generated.indexOf("</") !== -1;

    let contentWidget;
    if (isRichText) {
        contentWidget = <div className={"ck-content"} dangerouslySetInnerHTML={{__html: prompt.generated}} />;
    } else {
        contentWidget = <p>{prompt.generated}</p>;
    }

    const sectionClasses = "article-section-display "
        + (isRichText ? "rich-text-display" : "plain-text-display")

    return <div className={sectionClasses}>
        {/*If there is a heading, display it. TODO: This is outdated and was only used on*/}
        {/*the first few articles. We should just convert them to the new format.*/}
        {prompt.heading ? <h2>{prompt.heading}</h2> : null}
        {contentWidget}
    </div>
}