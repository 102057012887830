import React from "react";
import "./Footer.scss";
import {ArticulonInfoPopover} from "../components/ArticulonInfoPopover";


export function Footer() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return <div className={"footer"}>
        <span onClick={handleClick}>Get Articulon for your business</span>
        <a href={"https://www.bradleyarsenault.me/"} target="_blank" rel="noreferrer">Learn more about Brad</a>
        <ArticulonInfoPopover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
        />
    </div>
}
