import axios from "axios";
import {ensureMinimumPromiseResolveTime} from "../utils/utils";
import {createContext} from "react";
import { axiosETAGCache } from 'axios-etag-cache';
export const apiAccessTokenContext = createContext(null);

// Apply the axios ETAG interceptor
const axiosWithETAGCache = axiosETAGCache(axios);

let localGCPArticleCachePromise = null;
let localGCPArticleCache = null;

let apiWarmupPromise = null;

export const api = {
    // This function is used to make sure the API server is awake, in-case it
    // has to cold-start from zero containers.
    async startAPIWarmup() {
        if (apiWarmupPromise) {
            return apiWarmupPromise;
        } else {
            apiWarmupPromise = axios.get(`/`);
            return apiWarmupPromise;
        }
    },
    async getCompletion(prompt) {
        const postParam = {
            prompt: prompt
        }

        const response = await ensureMinimumPromiseResolveTime(axios.post(`/completion`, postParam), 500);

        return response.data;
    },
    async getGeneratedImage(prompt) {
        const queryParam = {
            prompt: prompt
        }

        const response = await ensureMinimumPromiseResolveTime(axios.get(`/generate_image`, {
            params: queryParam,
        }), 500);

        return response.data;
    },
    async getArticles() {
        const queryParams = {
        }

        const response = await axiosWithETAGCache.get(`/article`, {
            params: queryParams
        });

        return response.data;
    },
    async getArticlesFromGCPStorageCache() {
        if (localGCPArticleCache) {
            return localGCPArticleCache;
        } else if (localGCPArticleCachePromise) {
            return (await localGCPArticleCachePromise).data;
        }

        localGCPArticleCachePromise = axiosWithETAGCache.get(`${process.env.REACT_APP_FRONTEND_URL}storage/articles.json`);

        localGCPArticleCache = (await localGCPArticleCachePromise).data;

        return localGCPArticleCache;
    },
    async getArticle(articleId) {
        const queryParams = {
        }

        const response = await axiosWithETAGCache.get(`/article/${articleId}`, {
            params: queryParams
        });

        return response.data;
    },
    async getArticleFromGCPStorageCache(articleId) {
        const articlePromise = axiosWithETAGCache.get(`${process.env.REACT_APP_FRONTEND_URL}storage/article-${articleId}.json`);
        const articleData = (await articlePromise).data;
        return articleData;
    },
    async saveArticle(article) {
        const response = await axios.put(`/article/${article._id}`, article);

        return response.data;
    },
    async createNewArticle() {
        const queryParams = {
        }

        const response = await axios.post(`/article`, {
            params: queryParams
        });

        return response.data;
    },
}

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_API_URL;
axios.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error)
{
    if (error.response && error.response.status === 401)
    {
        // Auth0.logout();
        return (Promise.reject(error));
    }

    if (process.env.REACT_APP_DEBUG === "true")
    {
        alert(error.toString());
    } else {
        // Force reload the page. maybe this will help.
        // window.location.reload();
    }
    // Do something with response error
    return (Promise.reject(error));
});


