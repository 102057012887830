import "./TopBar.scss";
import AccountMenu from "./AccountMenu";
import {useAuth0} from "@auth0/auth0-react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";

export function TopBar({handleDrawerToggle}) {
    const {isAuthenticated} = useAuth0();

    return <div className={"top-bar"}>
        <div className={"logo-area"}>
            {/*<Toolbar>*/}
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{mr: 2, display: {md: 'none'}}}
            >
                <MenuIcon/>
            </IconButton>

            <a className={"new-title-wrapper"} href={"https://bradleyarsenault.me/"}>
                <div className={"new-title-decoration"}>
                    <svg preserveAspectRatio="xMidYMid meet" data-bbox="0 0 125 264" xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 125 264" height="264" width="125" data-type="color" role="presentation"
                         aria-hidden="true">
                        <defs>
                            <style>{"#comp-jtr47taj svg [data-color=\"1\"] {fill: #15E0FB;}"}</style>
                        </defs>
                        <g>
                            <path fill="#15E0FB" d="M104.167 0H125L20.833 264H0L104.167 0z" fill-rule="evenodd"
                                  data-color="1"></path>
                        </g>
                    </svg>
                </div>
                <span className={"new-title"}>
                    Bradley<br/>Arsenault
                </span>
            </a>

            <div className={"new-description-wrapper"}>
                <span className={"new-description"}>
                    AI Engineer<br/>Entrepreneur<br/>Freelancer
                </span>
            </div>

            {/*<div className={"logo-image"}>*/}
            {/*    <img src={"/logo.png"} alt={"logo"} className={"logo-image"}/>*/}
            {/*</div>*/}
            <span className={"logo-text"}>Blog</span>
        </div>


        {
            isAuthenticated ?
                <AccountMenu/>
                : null
        }
    </div>
}
