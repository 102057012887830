import React, {useEffect} from 'react';
import "./ArticleView.scss";
import ArticleTitle from "./ArticleTitle";
import {api} from "../../general/frontend/components/api";
import ArticleGenerator from "./ArticleGenerator";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {ArticleExplainer} from "./ArticleExplainer";
import {ArticleMetaTags} from "./ArticleMetaTags";
import CircularProgress from "@mui/material/CircularProgress";
import {ArticleGeneratedImage} from "./ArticleGeneratedImage";
import {ArticleHeader} from "./ArticleHeader";
import "./ckeditor-content-styles.css";

export function computeDocumentTitle(article) {
    return `${article.title} - ${process.env.REACT_APP_ENVIRONMENT_TITLE}`;
}

export function ArticleView(props) {
    const [article, setArticle] = React.useState(null);
    const [questionAnswer, setQuestionAnswer] = React.useState("");

    useEffect(() => {
        if (props.articleId) {
            if (process.env.REACT_APP_USE_GCP_STORAGE_ARTICLE_CACHE === "true") {
                api.getArticleFromGCPStorageCache(props.articleId).then((article) => {
                    setArticle(article);
                    setQuestionAnswer("");

                    // Set the window title
                    document.title = computeDocumentTitle(article);

                    // Start the warmup process asynchronously in the background
                    // This helps to ensure the API is alive and ready to start
                    // generating completions
                    api.startAPIWarmup().then(() => {
                        // Do nothing
                    });
                }).catch((error) => {
                    // Try loading from the API server instead
                    api.getArticle(props.articleId).then((article) => {
                        setArticle(article);
                    });
                });
            } else {
                api.getArticle(props.articleId).then((article) => {
                    setArticle(article);
                    setQuestionAnswer("");

                    // Set the window title
                    document.title = computeDocumentTitle(article);
                });
            }
        }
    }, [props.articleId, setArticle, setQuestionAnswer]);

    if (!article) {
        return <div className={"article-view loading"}>
            <CircularProgress />
        </div>;
    } else if (!questionAnswer && article.question) {
        return <ArticleExplainer
            article={article}
            questionAnswer={questionAnswer}
            setQuestionAnswer={setQuestionAnswer}
        />
    } else {
        return <div className={"article-view"}>
            <ArticleMetaTags article={article} />

            <Card>
                <CardContent>
                    <ArticleHeader article={article}/>

                    <ArticleTitle
                        article={article}
                    />

                    <ArticleGeneratedImage
                        article={article}
                    />

                    <ArticleGenerator
                        article={article}
                        answer={questionAnswer}
                    />

                </CardContent>
            </Card>
            <br/>
            <br/>
        </div>
    }
}

