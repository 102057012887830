import React, {useMemo} from "react";
import Avatar from "@mui/material/Avatar";
import "./ArticleHeader.scss";
import {ProportionHumanIndicator} from "./ProportionHumanIndicator";
import dayjs from "dayjs";

export function calculateMinutesToRead(article) {
    const wordsPerMinute = 200;
    let numberOfWords = 0;
    for (const prompt of article.prompts) {
        numberOfWords += prompt.generated.split(/[\s\W]+/g).length;
    }
    return Math.ceil(numberOfWords / wordsPerMinute);
}

export function ArticleHeader({article}) {
    const minutesToRead = useMemo(() => {
        if (article) {
            return calculateMinutesToRead(article);
        } else {
            return 0;
        }
    }, [article]);

    const dateForFormatted = dayjs(article.date).format("MMM DD, YYYY");

    return <div className={"article-header"}>
        <Avatar alt="Remy Sharp" src={`/${article.author_image || "profile.jpg"}`}/>
        <span>{article.author || "Bradley Arsenault"}</span>
        <span>-</span>
        <span>{dateForFormatted}</span>
        <span>-</span>
        <span>{minutesToRead} minute read</span>
        <span>-</span>
        <ProportionHumanIndicator article={article}/>
    </div>;
}