import "./LeftSideMenu.scss";
import * as React from 'react';
import {ArticleList} from "./ArticleList";
import Drawer from "@mui/material/Drawer";
import ListItem from "@mui/material/ListItem";
import LinkIcon from '@mui/icons-material/Link';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import {useCallback} from "react";
import {ArticulonInfoPopover} from "../components/ArticulonInfoPopover";



function LeftSideMenuContents({handleDrawerToggle}) {
    const [isShowingArticulonInfo, setIsShowingArticulonInfo] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePoweredByArticulonClick = useCallback((evt) => {
        setIsShowingArticulonInfo(true);
        setAnchorEl(evt.currentTarget);
    }, []);

    const handleClosePoweredByArticulonClick = useCallback(() => {
        setIsShowingArticulonInfo(false);
        setAnchorEl(null);
    }, []);


    return <>
        <div className={"menu-article-list-wrapper"}>
            <div className={"left-side-menu-section"}>
                <span className={"article-list-header"}>Links</span>
                <ListItem disablePadding>
                    <a className={"article-list-item"} href={"https://bradleyarsenault.me/"} target="__blank">
                        <div className={"title-icon-group"}>
                            <LinkIcon sx={{fontSize: 25}}/>
                            <span className={"article-title"}>Main website</span>
                        </div>
                    </a>
                </ListItem>
                <ListItem disablePadding>
                    <a className={"article-list-item"} href={"https://promptdesk.ai/podcast"} target="__blank">
                        <div className={"title-icon-group"}>
                            <PodcastsIcon sx={{fontSize: 25}}/>
                            <span className={"article-title"}>Podcast</span>
                        </div>
                    </a>
                </ListItem>
            </div>
            <div className={"left-side-menu-section"}>
                <span className={"article-list-header"}>Articles</span>
                <ArticleList onArticleSelected={handleDrawerToggle}/>
            </div>
        </div>
        <div className={"powered-by-articulon"} onClick={handlePoweredByArticulonClick}>
            <div className={"articulon-image"}>
                <img src={"/logo.webp"} alt={"logo"} className={"logo-image"}/>
            </div>
            <span>
                Powered By Articulon
            </span>
        </div>
        <ArticulonInfoPopover
            open={isShowingArticulonInfo}
            anchorEl={anchorEl}
            onClose={handleClosePoweredByArticulonClick}
        />
    </>
}


export default function LeftSideMenu({open, handleDrawerToggle}) {
    return (
        <>
            <Drawer
                classes={{
                    docked: 'left-side-menu',
                    paper: "left-side-menu-content",
                }}
                container={window.document.body}
                variant="temporary"
                open={open}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: {xs: 'block', md: 'none'},
                }}
            >
                <LeftSideMenuContents
                    handleDrawerToggle={handleDrawerToggle}
                />
            </Drawer>
            <Drawer
                classes={{
                    docked: 'left-side-menu',
                    paper: "left-side-menu-content",
                }}
                variant="permanent"
                sx={{
                    display: {xs: 'none', md: 'block'},
                }}
                open
            >
                <LeftSideMenuContents
                    handleDrawerToggle={handleDrawerToggle}
                />
            </Drawer>
        </>
    );
}
