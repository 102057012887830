import "./ArticleGeneratedImage.scss";

export function ArticleGeneratedImage({article}) {
    if (!article.image_prompt) {
        return null;
    }

    const alt = `Generated Image for ${article.title}`;

    return (
        <div className={"article-generated-image"}>
            <img
                src={`${process.env.REACT_APP_BACKEND_API_URL}generate_image?prompt=${article.image_prompt}`}
                alt={alt}
            />
        </div>
    );
}
