import React from 'react';
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";

export function ArticulonInfoPopover({ open, anchorEl, onClose }) {
    return (
        <Popover
            id={"articulon-info"}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Typography sx={{p: 2}}>
                Articulon is a custom tool originally built by Bradley Arsenault for his own blog.<br/><br/>
                To get Articulon at your business, please contact Bradley Arsenault to setup an
                installation on your own domain name and to customize the look and feel to your
                needs.<br/>
                <a href={"https://www.bradleyarsenault.me/contact-me"} target="_blank" rel="noreferrer">Contact Brad</a>
            </Typography>
        </Popover>
    );
}