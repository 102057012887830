import {NavLink} from "react-router-dom";
import * as React from "react";
import ListItem from "@mui/material/ListItem";
import DescriptionIcon from "@mui/icons-material/Description";
import {useAuth0} from "@auth0/auth0-react";
import "./ArticleListItem.scss";
import dayjs from "dayjs";


export function ArticleListItem({article, onArticleSelected}) {
    const { isAuthenticated } = useAuth0();

    let navLinkClasses = "article-list-item";
    if (!article.title) {
        navLinkClasses += " unnamed";
    }

    // Only show month and day, not year. Mostly due to the very limited space available
    const dateForDisplay = dayjs(article.date).format("MMM DD");

    return <ListItem key={article._id} disablePadding onClick={(evt) => {
        evt.stopPropagation()
    }}>
        <NavLink
            to={isAuthenticated ? `/article/${article._id}` : `/article/${article.slug}`}
            className={({isActive, isPending}) => {
                const stateClass = isActive ? "active" : isPending ? "pending" : "";
                return navLinkClasses + " " + stateClass;
            }}
            onClick={(evt) => {
                if (onArticleSelected) {
                    onArticleSelected();
                }
            }}
        >
            <div className={"title-icon-group"}>
                <div className={"article-icon-stack"}>
                    <DescriptionIcon sx={{fontSize: 25}}/>
                    <span className={"article-date"}>{dateForDisplay}</span>
                </div>

                <div className={"article-title-wrapper"}>
                    {
                        article.title && <span className={"article-title"}>{article.title}</span>
                    }
                    {
                        !article.title && <span className={"article-title"}>Unnamed...</span>
                    }
                </div>
            </div>
        </NavLink>
    </ListItem>
}