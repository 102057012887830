import React, {useMemo} from "react";
import {PieChart} from "@mui/x-charts/PieChart";
import "./ProportionHumanIndicator.scss";
import levenshtein from 'fast-levenshtein';

export function calculateProportionHumanMade(article) {
    let totalSizeOfOriginals = 0;
    let totalSizeOfGenerated = 0;
    let totalChangedSize = 0;
    for (const prompt of article.prompts) {
        totalSizeOfOriginals += prompt.generated.length;
        totalSizeOfGenerated += prompt.text.length;
        totalChangedSize += levenshtein.get(prompt.text, prompt.generated);
    }
    const proportionChanged = totalChangedSize / Math.max(totalSizeOfGenerated, totalSizeOfOriginals);
    const proportionHuman = 1.0 - proportionChanged;

    return Math.max(15, Math.min(100, Math.ceil(proportionHuman * 100)));
}

export function ProportionHumanIndicator(
    {article},
) {
    const proportionHuman = useMemo(() => {
        if (article) {
            return calculateProportionHumanMade(article);
        } else {
            return 0;
        }
    }, [article]);

    let proportionHumanForCircle = Math.min(proportionHuman, 85);

    // Don't apply min if proportion human is 100
    if (proportionHuman === 100) {
        proportionHumanForCircle = 100;
    }

    const aiDataConfig = {
        id: 0,
        value: (100 - proportionHumanForCircle),
        label: 'AI',
        color: '#ff4848',
        actual: (100 - proportionHuman)
    };

    const humanDataConfig = {
        id: 1,
        value: proportionHumanForCircle,
        label: 'Human',
        color: "#6f6ff8",
        actual: proportionHuman
    };

    let chartData = [aiDataConfig, humanDataConfig]
    if (proportionHuman === 100) {
        chartData = [humanDataConfig];
    }

    let startAngle = -((100 - proportionHumanForCircle) / 200) * 360 - 30;

    return (
        <div className={"ai-proportion-pie-chart"}>
            <PieChart
                series={[
                    {
                        data: chartData,
                        label: "%",
                        arcLabel: (item) => `${item.label}`,
                        innerRadius: 10,
                        outerRadius: 30,
                        startAngle: startAngle,
                        paddingAngle: 3,
                        valueFormatter: (val) => `${val.actual}%`,
                    },
                ]}
                slotProps={{legend: {hidden: true}}}
                width={180}
                height={60}
            />
        </div>
    );
}